.omponents__NoAppState__NoAppState_styles_ts-quickStartButton-qydoopl{height:40px;width:220px;}.theme-light .omponents__NoAppState__NoAppState_styles_ts-quickStartButton-qydoopl{color:#344054;}.theme-dark .omponents__NoAppState__NoAppState_styles_ts-quickStartButton-qydoopl{color:#344054;}
.omponents__NoAppState__NoAppState_styles_ts-noAppTitle-niagod0{line-height:1.5;}
.omponents__NoAppState__NoAppState_styles_ts-iconContainer-ijbpnhr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;border-radius:50%;background-color:#f3f6fd;padding:8px;}.omponents__NoAppState__NoAppState_styles_ts-iconContainer-ijbpnhr > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:8px;border-radius:50%;background-color:#e7edfd;}
.omponents__NoAppState__NoAppState_styles_ts-noAppContent-n1nl8ae0{max-width:455px;text-align:center;}.omponents__NoAppState__NoAppState_styles_ts-noAppContent-n1nl8ae0 h3{font-weight:500 !important;font-size:16px !important;line-height:24px !important;}
.omponents__NoAppState__NoAppState_styles_ts-createAppButton-c18hve4s{height:40px;width:220px;}
.omponents__NoAppState__NoAppState_styles_ts-mainContainer-mxzze06{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;gap:10px;}@media (max-width:799.98px){.omponents__NoAppState__NoAppState_styles_ts-mainContainer-mxzze06{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.omponents__NoAppState__NoAppState_styles_ts-mainContainer-mxzze06 > button{width:100%;}}
.omponents__NoAppState__NoAppState_styles_ts-buttonIcon-b5olqqs{margin-right:0.6rem;}
.omponents__NoAppState__NoAppState_styles_ts-noAppSubText-n1x4swoi{margin:0.5rem 0;font-weight:400;font-size:14px;line-height:20px;}.theme-light .omponents__NoAppState__NoAppState_styles_ts-noAppSubText-n1x4swoi{color:#667085;}.theme-dark .omponents__NoAppState__NoAppState_styles_ts-noAppSubText-n1x4swoi{color:#667085;}
.omponents__NoAppState__NoAppState_styles_ts-modelsStartButton-m1si962c{height:40px;width:300px;margin:0.5rem 0;}.theme-light .omponents__NoAppState__NoAppState_styles_ts-modelsStartButton-m1si962c{color:#344054;}.theme-dark .omponents__NoAppState__NoAppState_styles_ts-modelsStartButton-m1si962c{color:#344054;}
.omponents__NoAppState__NoAppState_styles_ts-createTaskLink-coqj7ss{cursor:pointer;}.theme-light .omponents__NoAppState__NoAppState_styles_ts-createTaskLink-coqj7ss{color:#004EEB;}.theme-dark .omponents__NoAppState__NoAppState_styles_ts-createTaskLink-coqj7ss{color:#004EEB;}
