.odules__Apps__components__AppViewer__AppViewer_styles_ts-container-c1renaw8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:10px;}
.odules__Apps__components__AppViewer__AppViewer_styles_ts-titleContainer-t1w9phmb{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;box-sizing:border-box;padding:40px 60px 30px 60px;}@media (max-width:799.98px){.odules__Apps__components__AppViewer__AppViewer_styles_ts-titleContainer-t1w9phmb{padding:20px 20px 0 20px;}}.odules__Apps__components__AppViewer__AppViewer_styles_ts-titleContainer-t1w9phmb .title{font-style:normal;font-weight:500;font-size:14px;line-height:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.odules__Apps__components__AppViewer__AppViewer_styles_ts-mainContainer-m1xmg3rc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-flex:3;-ms-flex:3;flex:3;min-width:0;}
.odules__Apps__components__AppViewer__AppViewer_styles_ts-mobileHeader-mz2uayr{margin-bottom:20px;}
.odules__Apps__components__AppViewer__AppViewer_styles_ts-detailsContainer-d13kgtrp{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:10px;}@media (max-width:799.98px){.odules__Apps__components__AppViewer__AppViewer_styles_ts-detailsContainer-d13kgtrp{margin-left:-20px;margin-right:-10px;margin-top:30px;}}
.odules__Apps__components__AppViewer__AppViewer_styles_ts-infoContainer-i1p08sol{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:30px;width:100%;min-width:0;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}@media (max-width:799.98px){.odules__Apps__components__AppViewer__AppViewer_styles_ts-infoContainer-i1p08sol{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:10px;}}
.odules__Apps__components__AppViewer__AppViewer_styles_ts-entityDetailsContainer-e11b0x0a{display:block;-webkit-flex:1;-ms-flex:1;flex:1;}
.odules__Apps__components__AppViewer__AppViewer_styles_ts-appImage-ahaispp{display:block;-webkit-flex:1;-ms-flex:1;flex:1;}.odules__Apps__components__AppViewer__AppViewer_styles_ts-appImage-ahaispp > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;min-height:0;}
.odules__Apps__components__AppViewer__AppViewer_styles_ts-appInfo-amfd8jq{display:block;-webkit-flex:2;-ms-flex:2;flex:2;min-width:0;}@media (max-width:799.98px){.odules__Apps__components__AppViewer__AppViewer_styles_ts-appInfo-amfd8jq{padding-top:20px;}}
.odules__Apps__components__AppViewer__AppViewer_styles_ts-paddedSection-pfjnz53{padding:0 60px 0 60px;}@media (max-width:799.98px){.odules__Apps__components__AppViewer__AppViewer_styles_ts-paddedSection-pfjnz53{padding:0 20px 0 20px;}}
.odules__Apps__components__AppViewer__AppViewer_styles_ts-mobileButtons-mcr9z9l{position:fixed;bottom:0;width:100%;padding:20px;background:white;}.odules__Apps__components__AppViewer__AppViewer_styles_ts-mobileButtons-mcr9z9l button{width:100% !important;font-weight:500 !important;font-size:14px !important;width:100% !important;height:40px !important;}
.odules__Apps__components__AppViewer__AppViewer_styles_ts-modelViewWrapper-mw5bdg3 .entityDetails li{position:relative;}.odules__Apps__components__AppViewer__AppViewer_styles_ts-modelViewWrapper-mw5bdg3 .starButton{outline:none;}.odules__Apps__components__AppViewer__AppViewer_styles_ts-modelViewWrapper-mw5bdg3 .tabList{padding:calc( 2.8rem / 2 );}.odules__Apps__components__AppViewer__AppViewer_styles_ts-modelViewWrapper-mw5bdg3 .tabList .tabItem{font-size:0.875rem;opacity:0.4;font-weight:600;}.odules__Apps__components__AppViewer__AppViewer_styles_ts-modelViewWrapper-mw5bdg3 .tabList .tabItem[aria-selected='true']{opacity:1;}.odules__Apps__components__AppViewer__AppViewer_styles_ts-modelViewWrapper-mw5bdg3 .tabList .tabItem:not(:last-child){margin-right:1rem;}
