.odules__Auth__components__Signup__Signup_styles_ts-formGroup-f796g7i{margin-bottom:1.2rem;}
.odules__Auth__components__Signup__Signup_styles_ts-formGroupLast-f1bhqmpa{margin-bottom:1.25rem;}
.odules__Auth__components__Signup__Signup_styles_ts-logo-lramqv0{display:block;outline:none;margin-bottom:2.8rem;}
.odules__Auth__components__Signup__Signup_styles_ts-logoImage-l8rltm9{height:2rem;width:9.375rem;background:url(/clarifai-logo-with-name.svg) no-repeat;}
.odules__Auth__components__Signup__Signup_styles_ts-title-t12w415i{margin-bottom:1.625rem;}
.odules__Auth__components__Signup__Signup_styles_ts-forms-fpaxjwm{margin:0 0 1.25rem;}
.odules__Auth__components__Signup__Signup_styles_ts-checkboxes-c14b1f6s{margin-bottom:1.25rem;}.odules__Auth__components__Signup__Signup_styles_ts-checkboxes-c14b1f6s span{white-space:initial;}
.odules__Auth__components__Signup__Signup_styles_ts-enforceInputHeight-e1mng6l8 input{min-height:2.5rem;}
.odules__Auth__components__Signup__Signup_styles_ts-fieldLabel-f9bthxl{display:block;font-weight:600;margin-bottom:5px;font-size:0.75rem !important;line-height:1.125rem;}.theme-light .odules__Auth__components__Signup__Signup_styles_ts-fieldLabel-f9bthxl{color:#344054;}.theme-dark .odules__Auth__components__Signup__Signup_styles_ts-fieldLabel-f9bthxl{color:#344054;}
.odules__Auth__components__Signup__Signup_styles_ts-textLink-t1r4pjpv{font-size:inherit;line-height:inherit;border:none;}.theme-light .odules__Auth__components__Signup__Signup_styles_ts-textLink-t1r4pjpv{color:#004EEB;}.theme-dark .odules__Auth__components__Signup__Signup_styles_ts-textLink-t1r4pjpv{color:#004EEB;}
.odules__Auth__components__Signup__Signup_styles_ts-captcha-c1g7n21d{margin:0 0 1rem;}
