.tyles__typography_ts-smallTitle-sxjiwsi{font-size:0.7rem;line-height:1.5rem;font-weight:600;-webkit-letter-spacing:0.1em;-moz-letter-spacing:0.1em;-ms-letter-spacing:0.1em;letter-spacing:0.1em;text-transform:uppercase;color:#000;opacity:0.5;}
.tyles__typography_ts-bigTitle-b1muvu8a{font-size:3.75rem;line-height:4.5rem;font-weight:600;}
.tyles__typography_ts-titles-trobypy{font-size:1.125rem;line-height:1.25rem;font-weight:600;text-transform:capitalize;}
.tyles__typography_ts-titlesAdaptive-t1avs9t3{font-size:1.75rem;line-height:2rem;font-weight:600;text-transform:capitalize;}@media screen and (min-width:800px){.tyles__typography_ts-titlesAdaptive-t1avs9t3{font-size:36px;line-height:44px;}}
.tyles__typography_ts-sectionTitle-s1old27y{font-weight:600;font-size:1rem;line-height:2.85rem;color:#000000;}
.tyles__typography_ts-actionModalTitle-aas7n4z{font-weight:600;font-size:2.25rem;line-height:2.75rem;color:#000000;}
.tyles__typography_ts-actionModalDescription-a1nurjjs{font-size:1rem;line-height:1.5rem;}
.tyles__typography_ts-tinyLinkButton-t1b5ormu{text-transform:uppercase;color:#195AFF;-webkit-text-decoration:underline;text-decoration:underline;font-weight:600;font-size:10px;}
.tyles__typography_ts-bigH1-bhyi79h{font-size:1.875rem;line-height:1.875rem;font-weight:600;}
.tyles__typography_ts-regularText-r4kmfzb{font-size:1.125rem;font-weight:400;}
.tyles__typography_ts-italic-i5opr1h{font-style:italic;}
.tyles__typography_ts-fontRegular-f1dtku18{font-weight:400;}
.tyles__typography_ts-fontMedium-fme6mjm{font-weight:500;}
.tyles__typography_ts-fontBold-f1yty5cj{font-weight:600;}
.tyles__typography_ts-link-l8plbaq{-webkit-text-decoration:underline;text-decoration:underline;color:#195AFF;}
.tyles__typography_ts-textSmall-t1cd47hr{font-size:0.75rem;line-height:1.125rem;}
.tyles__typography_ts-textRegularLight-tw5jo8{font-size:0.875rem;line-height:150%;}
.tyles__typography_ts-textTitle-t196yqao{font-size:1.5rem;line-height:32px;}
.tyles__typography_ts-textBig-t1y5bxo9{font-size:1.875rem;line-height:1.875rem;}
.tyles__typography_ts-textCaption-t11sqpt{font-size:0.625rem;font-weight:600;text-transform:uppercase;}
.tyles__typography_ts-inputInfo-ilvs2p2{color:rgba(0,0,0,0.5);font-size:calc(2.8rem / 3);font-weight:normal;margin:0;}
