.omponents__Navbar__Navbar_styles_ts-nav-n1x0kh9h{width:100%;z-index:999;position:relative;}.theme-light .omponents__Navbar__Navbar_styles_ts-nav-n1x0kh9h{background-color:#ffffff;color:#344054;border-bottom:1px solid #E4E7EC;}.theme-dark .omponents__Navbar__Navbar_styles_ts-nav-n1x0kh9h{background-color:black;color:#344054;border-bottom:1px solid #E4E7EC;}
.omponents__Navbar__Navbar_styles_ts-navContent-ndy5qxz{position:relative;padding:0 1rem;height:calc(4.375rem - 1px);}
@media (max-width:799.98px){.omponents__Navbar__Navbar_styles_ts-hideBelowTab-hj9bowv{display:none !important;}}
@media (min-width:800px){.omponents__Navbar__Navbar_styles_ts-hideAboveTab-hyb8b34{display:none !important;}}
.omponents__Navbar__Navbar_styles_ts-_baseImage-_8knknk{width:2.5rem;height:2.5rem;background-size:contain;background-position:center;background-repeat:no-repeat;}
.omponents__Navbar__Navbar_styles_ts-_logo-_1pbrb98{background-image:url(/favicon.svg);float:left;height:1.875rem;}
.omponents__Navbar__Navbar_styles_ts-_profile-_1885f31{float:right;}.theme-light .omponents__Navbar__Navbar_styles_ts-_profile-_1885f31{background-color:rgb(0 0 0 / 7%);}.theme-dark .omponents__Navbar__Navbar_styles_ts-_profile-_1885f31{background-color:rgb(58 58 59);}
.omponents__Navbar__Navbar_styles_ts-_baseIcon-_kfpm5m{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
.omponents__Navbar__Navbar_styles_ts-profile-pgm5nmc{border:2px solid transparent;-webkit-transition:all 0.5ms ease-in;transition:all 0.5ms ease-in;}.omponents__Navbar__Navbar_styles_ts-profile-pgm5nmc:hover{opacity:0.9;}.omponents__Navbar__Navbar_styles_ts-profile-pgm5nmc:active,.omponents__Navbar__Navbar_styles_ts-profile-pgm5nmc.active{opacity:1;}.theme-light .omponents__Navbar__Navbar_styles_ts-profile-pgm5nmc:active,.theme-light .omponents__Navbar__Navbar_styles_ts-profile-pgm5nmc.active{border-color:#99B8F7;}.theme-dark .omponents__Navbar__Navbar_styles_ts-profile-pgm5nmc:active,.theme-dark .omponents__Navbar__Navbar_styles_ts-profile-pgm5nmc.active{border-color:#99B8F7;}
.theme-light .omponents__Navbar__Navbar_styles_ts-navLink__Active-n6xg5mv:hover{color:#101828;background-color:#F2F6FE;}.theme-dark .omponents__Navbar__Navbar_styles_ts-navLink__Active-n6xg5mv:hover{color:#101828;background-color:#F2F6FE;}.theme-light .omponents__Navbar__Navbar_styles_ts-navLink__Active-n6xg5mv:active,.theme-light .omponents__Navbar__Navbar_styles_ts-navLink__Active-n6xg5mv.active{color:#195AFF;background-color:#F2F6FE;}.theme-dark .omponents__Navbar__Navbar_styles_ts-navLink__Active-n6xg5mv:active,.theme-dark .omponents__Navbar__Navbar_styles_ts-navLink__Active-n6xg5mv.active{color:#195AFF;background-color:#F2F6FE;}
.omponents__Navbar__Navbar_styles_ts-navLink__Radius-nat7fs8{border-radius:0.25rem;}
.omponents__Navbar__Navbar_styles_ts-navLink__Text-n1xjo8a6{display:inline-block;padding:0.75rem;font-size:0.875rem;font-weight:500;min-width:0;display:inline-block;max-width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;word-wrap:normal;}.theme-light .omponents__Navbar__Navbar_styles_ts-navLink__Text-n1xjo8a6{color:#344054;}.theme-dark .omponents__Navbar__Navbar_styles_ts-navLink__Text-n1xjo8a6{color:#344054;}
.omponents__Navbar__Navbar_styles_ts-buttonIcon-b1hl153r{margin-right:5px;}@media (max-width:1023.98px){.omponents__Navbar__Navbar_styles_ts-buttonIcon-b1hl153r{margin-right:0px;}}
.omponents__Navbar__Navbar_styles_ts-spacer-s12xr55x{-webkit-flex:1;-ms-flex:1;flex:1;}
.omponents__Navbar__Navbar_styles_ts-navAuthWrapper-n1g7gaxg{position:relative;gap:1rem;}.omponents__Navbar__Navbar_styles_ts-navAuthWrapper-n1g7gaxg *{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}.omponents__Navbar__Navbar_styles_ts-navAuthWrapper-n1g7gaxg a,.omponents__Navbar__Navbar_styles_ts-navAuthWrapper-n1g7gaxg button{margin:0;}.theme-light .omponents__Navbar__Navbar_styles_ts-navAuthWrapper-n1g7gaxg{color:#101828;}.theme-dark .omponents__Navbar__Navbar_styles_ts-navAuthWrapper-n1g7gaxg{color:#101828;}@media (max-width:1023.98px){.omponents__Navbar__Navbar_styles_ts-navAuthWrapper-n1g7gaxg{gap:0.5rem;}}
.omponents__Navbar__Navbar_styles_ts-navBarDropdown-n14vkbs0{outline:none;padding:0.625rem;border:2px solid transparent;-webkit-transition:all 0.5ms ease-in;transition:all 0.5ms ease-in;}.omponents__Navbar__Navbar_styles_ts-navBarDropdown-n14vkbs0:focus{outline:none;}.omponents__Navbar__Navbar_styles_ts-navBarDropdown-n14vkbs0:hover{background:rgb(255 255 255 / 20%);}.omponents__Navbar__Navbar_styles_ts-navBarDropdown-n14vkbs0:active,.omponents__Navbar__Navbar_styles_ts-navBarDropdown-n14vkbs0.active{box-shadow:0px 0px 0px 4px #e6edfd;background:rgb(255 255 255 / 20%);}.omponents__Navbar__Navbar_styles_ts-navBarDropdown-n14vkbs0:active:hover,.omponents__Navbar__Navbar_styles_ts-navBarDropdown-n14vkbs0.active:hover{box-shadow:0px 0px 0px 4px #e6edfd;}@media (max-width:799.98px){.omponents__Navbar__Navbar_styles_ts-navBarDropdown-n14vkbs0 .arrow{display:none;}}
.omponents__Navbar__Navbar_styles_ts-createAppBtn-c3ys3go{border-radius:4px;}.theme-light .omponents__Navbar__Navbar_styles_ts-createAppBtn-c3ys3go{color:#195AFF;}.theme-dark .omponents__Navbar__Navbar_styles_ts-createAppBtn-c3ys3go{color:#195AFF;}
.omponents__Navbar__Navbar_styles_ts-popoverSectionTitle-prro349{padding-right:8px;padding-left:8px;color:#a0a5ae;text-transform:uppercase;font-size:10px;font-weight:500;line-height:18px;-webkit-letter-spacing:0.06em;-moz-letter-spacing:0.06em;-ms-letter-spacing:0.06em;letter-spacing:0.06em;}
.omponents__Navbar__Navbar_styles_ts-accountMenuItem-a2pzrk1{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:0;border-radius:0.25rem;-webkit-transition:background 0.2s ease,color 0.2s ease;transition:background 0.2s ease,color 0.2s ease;padding:5px 8px;}.omponents__Navbar__Navbar_styles_ts-accountMenuItem-a2pzrk1:hover{color:#195aff;background:#f2f6fe;}.omponents__Navbar__Navbar_styles_ts-accountMenuItem-a2pzrk1 span{margin-bottom:0;}
.omponents__Navbar__Navbar_styles_ts-accountMenuGroupTitle-a1ja01zv{padding:0 0.5rem;margin-bottom:0.5rem;}
.omponents__Navbar__Navbar_styles_ts-accountMenuGroup-a1jmfthj{padding:0 0.5rem 1rem 0.5rem;}
.omponents__Navbar__Navbar_styles_ts-menuText-m1ra2uzb{font-size:14px;}
.omponents__Navbar__Navbar_styles_ts-accountPopoverLayout-au9xq96{padding:1rem 0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.omponents__Navbar__Navbar_styles_ts-accountPopover-a1jdoz7l{max-width:min(300px,100vw);}
.omponents__Navbar__Navbar_styles_ts-btnNoBorder-b408x3v:not(.active){border-color:transparent !important;box-shadow:none !important;}
.theme-light .omponents__Navbar__Navbar_styles_ts-badgeStyles-b50ukd9 .MuiBadge-colorError{background-color:#F04438;}.theme-dark .omponents__Navbar__Navbar_styles_ts-badgeStyles-b50ukd9 .MuiBadge-colorError{background-color:#F04438;}.omponents__Navbar__Navbar_styles_ts-badgeStyles-b50ukd9 .MuiBadge-badge{min-width:0.875rem;min-height:0.875rem;width:0.875rem;height:0.875rem;}
.omponents__Navbar__Navbar_styles_ts-notiticationPopoverStyles-nhrgqdh{min-width:auto;max-width:20.25rem;}@media (max-width:799.98px){.omponents__Navbar__Navbar_styles_ts-notiticationPopoverStyles-nhrgqdh{visibility:hidden;}}
@media (min-width:800px){.omponents__Navbar__Navbar_styles_ts-notificationModalWrapperStyles-n15y75iy{visibility:hidden;}}
.omponents__Navbar__Navbar_styles_ts-notificationModalStyles-n911j7n{max-height:80vh !important;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.omponents__Navbar__Navbar_styles_ts-notificationModalStyles-n911j7n .heading{min-height:4.375rem;height:4.375rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;padding:0 16px;}.theme-light .omponents__Navbar__Navbar_styles_ts-notificationModalStyles-n911j7n .heading{border-bottom:1px solid #E4E7EC;}.theme-dark .omponents__Navbar__Navbar_styles_ts-notificationModalStyles-n911j7n .heading{border-bottom:1px solid #E4E7EC;}.omponents__Navbar__Navbar_styles_ts-notificationModalStyles-n911j7n .body{padding:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:24px;-webkit-flex:1;-ms-flex:1;flex:1;}.omponents__Navbar__Navbar_styles_ts-notificationModalStyles-n911j7n .scroll-wrapper{-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:24px;overflow:auto;}.omponents__Navbar__Navbar_styles_ts-notificationModalStyles-n911j7n h1{font-weight:600;font-size:24px;line-height:32px;}.theme-light .omponents__Navbar__Navbar_styles_ts-notificationModalStyles-n911j7n h1{color:#101828;}.theme-dark .omponents__Navbar__Navbar_styles_ts-notificationModalStyles-n911j7n h1{color:#101828;}
.omponents__Navbar__Navbar_styles_ts-emptyNotifications-egla7bw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;gap:8px;width:100%;-webkit-flex:1;-ms-flex:1;flex:1;max-height:80%;}@media (min-width:800px){.omponents__Navbar__Navbar_styles_ts-emptyNotifications-egla7bw{min-width:calc(320px - 2rem);}}.omponents__Navbar__Navbar_styles_ts-emptyNotifications-egla7bw h2{font-size:14px;font-weight:500;line-height:20px;}.theme-light .omponents__Navbar__Navbar_styles_ts-emptyNotifications-egla7bw h2{color:#344054;}.theme-dark .omponents__Navbar__Navbar_styles_ts-emptyNotifications-egla7bw h2{color:#344054;}.omponents__Navbar__Navbar_styles_ts-emptyNotifications-egla7bw p{font-size:12px;font-weight:400;line-height:18px;}.theme-light .omponents__Navbar__Navbar_styles_ts-emptyNotifications-egla7bw p{color:#667085;}.theme-dark .omponents__Navbar__Navbar_styles_ts-emptyNotifications-egla7bw p{color:#667085;}
@media (max-width:1279.98px){.omponents__Navbar__Navbar_styles_ts-desktopAccountSelector-d17kx0br{display:none;}}
@media (min-width:1279.98px){.omponents__Navbar__Navbar_styles_ts-mobileAccountSelector-mtygl7q{display:none;}}
