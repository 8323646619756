.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthContainer-tscy6h6{max-width:444px;padding:0 1.5rem;margin:auto;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthBody-t1st54gs{text-align:center;margin-top:100px;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthHeading-ttvvkfu{line-height:2.37rem;margin:1.25rem 0 0.5rem;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthTextGrey-t126f2sb{font-size:1rem;line-height:1.5;margin-bottom:2rem;}.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthTextGrey-t126f2sb span{display:block;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthInput-tlx0aez{width:100%;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLabel-tr0hwo1{display:block;font-size:0.875rem;line-height:1.25rem;text-align:left;margin-bottom:1.5rem;}.theme-light .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLabel-tr0hwo1{color:#344054;}.theme-dark .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLabel-tr0hwo1{color:#344054;}.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLabel-tr0hwo1 span{display:block;font-weight:500;margin-bottom:0.35rem;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthButton-tn0u7wl{margin-bottom:2rem;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLink-tvhjlku,.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLink-tvhjlku.btn-link{display:inline-block;font-size:inherit;margin:0;padding:0;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthHint-t1bypljt{font-weight:500;margin-bottom:2rem;}.theme-light .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthHint-t1bypljt{color:#667085;}.theme-dark .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthHint-t1bypljt{color:#667085;}.theme-light .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthHint-t1bypljt a{color:#195AFF;}.theme-dark .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthHint-t1bypljt a{color:#4c7fff;}@media (max-width:799.98px){.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthHint-t1bypljt{display:none;}}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorLoginContainer-t14magdv button{height:3rem;}.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorLoginContainer-t14magdv form{margin-bottom:0.75rem;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLoginHint-t1fdih3n{margin-bottom:2rem;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthLoginSubHeader-tum2aws{margin-top:2rem;margin-bottom:2rem;font-weight:500;font-size:1.5rem;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-weight:500;font-size:0.875rem;line-height:1.25rem;}.theme-light .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91,.theme-light .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91:link,.theme-light .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91:visited,.theme-light .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91:focus,.theme-light .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91:hover,.theme-light .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91:active{color:#667085;}.theme-dark .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91,.theme-dark .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91:link,.theme-dark .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91:visited,.theme-dark .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91:focus,.theme-dark .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91:hover,.theme-dark .odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91:active{color:#667085;}.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorAuthNav-ternn91 svg{margin-right:0.5rem;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorLoginLinkText-t17ohv03{margin-top:2rem;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFactorLoginNav-tuhfq2{margin-top:2rem;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFARecoveryInput-t1jipu46{width:100%;text-align:left;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFARecoveryForm-tqr9szb{width:100%;}
.odules__Auth__components__TwoFactorAuth__TwoFactorAuth_styles_ts-twoFARecoveryButton-t1vwdzzr{width:100%;}
